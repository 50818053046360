.wrapper {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;
    position: relative;
  }

  &.hidden {
    display: none;
  }
}

.content {
  background-color: var(--bg);
  color: var(--textPrimary);
  padding: 10px;
  max-width: 380px;
  min-width: 60px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.arrow {
  content: ' ';
  height: 10px;
  width: 10px;
  position: fixed;
  pointer-events: none;
  background-color: var(--bg);
}

@media only screen and (max-width: 440px) {
  .content {
    max-width: calc(100vw - 30px);
  }
}
